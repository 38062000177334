<!--统计下级代理商的用户某个时间段切割数量-->
<template>
    <viewTable
        ref="viewTableRef"
    />
</template>

<script>
import viewTable from "./template/viewTable";
export default {
    name: "cut",
    components:{
        viewTable
    },
    data(){
        return {
            lang:this.$lang.getLang('agentStatistics','cut'),
        }
    },
    methods:{

    },
    mounted() {
        this.$refs.viewTableRef.init('agentReport.report/getUserCutNum');
        // this.$refs.viewTableRef.explain = '统计下级代理商的用户某个时间段切割数量';
        this.$refs.viewTableRef.explain = this.lang['explain'];
    }
}
</script>

<style scoped>

</style>